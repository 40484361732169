(function ($, window, document, undefined) {

    var StepIndicator = function (scope) {
        this.owl = scope;
        this.owl._options = $.extend(StepIndicator.Defaults, this.owl.options);

        if (this.owl._options.stepIndicator && this.owl._options.nav) {

            this.handlers = {
                'initialized.owl.carousel': this.build.bind(this)
            };

            this.owl.$element.on(this.handlers);
        }
    };

    StepIndicator.Defaults = {
        indicatorSeparatorText: 'of',
        indicatorClass: 'owl-indicator',
        indicatorCountClass: 'owl-count',
        indicatorSeparatorClass: 'owl-separator',
        indicatorTotalClass: 'owl-total'
    };

    StepIndicator.prototype.build = function (evt) {

        this.$nav = this.owl.$element.find('.' + this.owl.settings.navContainerClass.split(' ').join('.'));
        this.$container = $('<div class="' + this.owl._options.indicatorClass + '"/>');
        this.$count = $('<span class="' + this.owl._options.indicatorCountClass + '"/>');
        this.$total = $('<span class="' + this.owl._options.indicatorTotalClass + '"/>');

        var $separator = $('<span class="' + this.owl._options.indicatorSeparatorClass + '">' + this.owl._options.indicatorSeparatorText + '</span>');

        this.$container.append(this.$count, $separator, this.$total);
        this.$nav.append(this.$container);

        var handler = this.changed.bind(this);
        var handlers = {
            'changed.owl.carousel': handler
        };

        this.owl.$element.on(handlers);
        $.extend(this.handlers, handlers);

        handler(evt);
    };

    StepIndicator.prototype.changed = function (evt) {

        var count = evt.item.index + 1,
            total = (evt.item.count - evt.page.size) + 1;

        this.$nav.toggle(evt.page.size < evt.item.count);
        this.$count.text(count);
        this.$total.text(total);
    };

    StepIndicator.prototype.destroy = function () {
        this.stop();

        this.handlers.forEach(function (handler) {
            this.$owl.$element.off(handler);
        }.bind(this));
    };

    $.fn.owlCarousel.Constructor.Plugins.stepIndicator = StepIndicator;

}(window.jQuery, window, document));
